import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface AuthState {
  token?: string;
}

const initialState: AuthState = {
  token: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },
  },
});

export const { updateToken } = authSlice.actions;

export const selectAuthToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;

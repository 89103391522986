import { FlashbarProps } from '@cloudscape-design/components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface Notification {
  type: FlashbarProps.MessageDefinition['type'];
  content: string;
  id: number;
}

let id = 0;

export interface NotificationState {
  notifications: Notification[];
}

const initialState: NotificationState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Omit<Notification, 'id'>>) => {
      state.notifications.push({
        ...action.payload,
        id,
      });
      id += 1;
    },
    dismissNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(x => x.id !== action.payload);
    },
  },
});

export const { addNotification, dismissNotification } = notificationSlice.actions;
export const selectNotifications = (state: RootState) => state.notification.notifications;
export default notificationSlice.reducer;

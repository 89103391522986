import { AppLayout, SideNavigation, SideNavigationProps } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import '@cloudscape-design/global-styles/index.css';
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import netlifyIdentity from 'netlify-identity-widget';
import MyFlashbar from './components/MyFlashbar';
import { useAuth } from './hooks/auth';
import { selectAuthToken } from './redux/authSlice';
import { useAppSelector } from './redux/hooks';

interface AppProps {
  router: ReturnType<typeof createBrowserRouter>;
}

function App(props: AppProps) {
  // TODO: maybe change based on page
  const [navOpen, setNavOpen] = useState(true);
  useEffect(() => applyMode(Mode.Dark), []);
  useAuth(); // Init netlify
  const user = useAppSelector(selectAuthToken);

  const logoutHref = '#logout';
  const logoutItems: SideNavigationProps.Item[] = user
    ? [
      {
        type: 'divider',
      },
      {
        type: 'link',
        href: logoutHref,
        text: 'Log out',
      },
    ]
    : [];

  return (
    <AppLayout
      // TODO: breadcrumbs
      maxContentWidth={Number.MAX_VALUE}
      stickyNotifications
      notifications={<MyFlashbar />}
      navigationOpen={navOpen}
      onNavigationChange={(event) => {
        setNavOpen(event.detail.open);
      }}
      navigation={
        <SideNavigation
          header={{
            href: '/',
            text: 'Professional Diary',
          }}
          onFollow={(e) => {
            e.preventDefault();
            if (e.detail.href === logoutHref) {
              netlifyIdentity.logout();
            } else {
              props.router.navigate(e.detail.href);
            }
          }}
          items={[
            {
              type: 'link',
              text: 'Add entry',
              href: '/',
            },
            {
              type: 'divider',
            },
            {
              type: 'section-group',
              title: 'Summarise',
              items: [
                {
                  type: 'link',
                  text: 'Week',
                  href: '/summarise/week',
                },
                {
                  type: 'link',
                  text: 'Month',
                  href: '/summarise/month',
                },
              ],
            },
            ...logoutItems,
          ]}
        />
      }
      toolsHide={true}
      content={<RouterProvider router={props.router} />}
    />
  );
}

export default App;

import { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { addNotification } from '../redux/notificationSlice';

export const useRtkErrorNotification = (error: object | undefined, isError: boolean, message: string) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (error || isError) {
      dispatch(addNotification({
        type: 'error',
        content: `${message}: ${error ? JSON.stringify(error) : 'Unknown error'}`,
      }));
    }
  }, [dispatch, error, isError, message]);
};

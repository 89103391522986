import { configureStore } from '@reduxjs/toolkit';
import { diaryApi } from './apiSlice';
import authReducer from './authSlice';
import notificationReducer from './notificationSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    [diaryApi.reducerPath]: diaryApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(diaryApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

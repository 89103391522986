import {
  Button,
  Cards,
  Container,
  ContentLayout,
  DatePicker,
  Form,
  FormField,
  Header,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useRtkErrorNotification } from '../hooks/rtkErrorNotification';
import { useAddWeekSummaryMutation, useGetWeekQuery, useGetWeekSummaryQuery } from '../redux/apiSlice';
import { useAppDispatch } from '../redux/hooks';
import { addNotification } from '../redux/notificationSlice';
import { monday, nicefyDate } from '../utils/dateUtils';
import { AuthedComponent } from './AuthedComponent';
import { EmptyCollection } from './EmptyCollection';
import { LineWrapper } from './LineWrapper';

const WeekSummary: React.FC = () => {
  const [summary, setSummary] = useState('');
  const [week, setWeek] = useState(monday());
  const payload = useMemo(() => ({ date: week.replace(/-/g, '') }), [week]);
  const { data, error, isLoading, isError } = useGetWeekQuery(payload);
  const {
    data: existingSummary,
    currentData: currentSummary,
    error: summaryError,
    isError: isSummaryError,
    isLoading: summaryIsLoading,
  } = useGetWeekSummaryQuery(payload);
  const entries = data?.data;
  const [addWeekSummary, { isLoading: adding, isSuccess, isError: isAddError, data: result }] =
    useAddWeekSummaryMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSummary(existingSummary?.Summary ?? '');
  }, [existingSummary]);

  // TODO: this could use some DRY (same basically as add entry and month summary)
  useEffect(() => {
    if (adding) return;
    if (isSuccess) {
      dispatch(addNotification({
        type: 'success',
        content: result?.Message ?? 'Successfully added week summary',
      }));
    } else if (isAddError) {
      dispatch(addNotification({
        type: 'error',
        content: result?.Message ?? 'Failed to add week summary',
      }));
    }
  }, [adding, isSuccess, isAddError, result, dispatch]);

  useRtkErrorNotification(error, isError, 'Failed to fetch week entries');
  useRtkErrorNotification(summaryError, isSummaryError, 'Failed to fetch week summary');

  const fetching = isLoading || summaryIsLoading;

  return (
    <ContentLayout
      header={
        <Header variant='h1' description='Summarise a week'>
          Summarise Week
        </Header>
      }
    >
      <AuthedComponent>
        <SpaceBetween direction='vertical' size='xl'>
          <Form
            actions={
              <Button
                variant='primary'
                disabled={fetching}
                loading={adding}
                onClick={(e) => {
                  e.preventDefault();
                  addWeekSummary({ ...payload, summary, update: !!currentSummary });
                }}
              >
                Submit
              </Button>
            }
          >
            <Container>
              <SpaceBetween direction='vertical' size='l'>
                <FormField label='Week summary'>
                  <Textarea
                    value={summary}
                    onChange={({ detail }) => setSummary(detail.value)}
                    disabled={summaryIsLoading}
                  />
                </FormField>
                <FormField label='Week'>
                  <DatePicker
                    isDateEnabled={date => date.getDay() == 1}
                    value={week}
                    onChange={({ detail }) => {
                      if (detail.value !== week) {
                        setSummary('');
                      }
                      setWeek(detail.value);
                    }}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
          <Cards
            items={entries !== undefined ? entries : []}
            loading={isLoading}
            empty={<EmptyCollection name='entries' />}
            cardDefinition={{
              header: e => nicefyDate(e.Date),
              sections: [
                {
                  id: 'entry',
                  content: e => <LineWrapper text={e.Entry} />,
                },
              ],
            }}
            header={<Header>Entries for this week</Header>}
          />
        </SpaceBetween>
      </AuthedComponent>
    </ContentLayout>
  );
};

export { WeekSummary };

import netlifyIdentity from 'netlify-identity-widget';
import { useEffect } from 'react';
import { updateToken } from '../redux/authSlice';
import { useAppDispatch } from '../redux/hooks';

const useAuth = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    netlifyIdentity.on('init', user => {
      console.log('Got user', user);
      dispatch(updateToken(user?.token?.access_token));
      if (user) {
        netlifyIdentity.refresh().then(t => {
          console.log(`Refreshed and got ${t}`);
          dispatch(updateToken(t));
        });
      }
    });
    netlifyIdentity.on('login', user => {
      console.log('Got user', user);
      dispatch(updateToken(user.token?.access_token));
    });
    netlifyIdentity.on('logout', () => {
      console.log('Cleared user');
      dispatch(updateToken());
    });
    try {
      netlifyIdentity.init();
    } catch (e) {
      console.error('Failed to init', e);
    }
    setInterval(() => {
      void netlifyIdentity.refresh().then(t => dispatch(updateToken(t)));
    }, 3600000);
  }, [dispatch]);
};

export { useAuth };

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import { AddEntry } from './components/AddEntry.tsx';
import { MonthSummary } from './components/MonthSummary.tsx';
import { WeekSummary } from './components/WeekSummary.tsx';
import store from './redux/store';

// Need to set up a redirect rule for this
// https://docs.netlify.com/routing/redirects/rewrites-proxies/#history-pushstate-and-single-page-apps
const router = createBrowserRouter([
  {
    path: '/',
    element: <AddEntry />,
  },
  {
    path: '/summarise/week',
    element: <WeekSummary />,
  },
  {
    path: '/summarise/month',
    element: <MonthSummary />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App router={router} />
    </Provider>
  </React.StrictMode>,
);

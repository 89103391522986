import {
  Button,
  Container,
  ContentLayout,
  DatePicker,
  Form,
  FormField,
  Header,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import { useAddEntryMutation } from '../redux/apiSlice';
import { useAppDispatch } from '../redux/hooks';
import { addNotification } from '../redux/notificationSlice';
import { toDatePickerString } from '../utils/dateUtils';
import { AuthedComponent } from './AuthedComponent';

const AddEntry: React.FC = () => {
  // TODO: validation
  const [entry, setEntry] = useState('');
  const [date, setDate] = useState(toDatePickerString(new Date()));
  const [addEntry, { isLoading, isSuccess, isError, data }] = useAddEntryMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading) return;
    if (isSuccess) {
      dispatch(addNotification({
        type: 'success',
        content: `Successfully added entry ${data?.warn ? `: ${data?.Message}` : ''}`,
      }));
    } else if (isError) {
      dispatch(addNotification({
        type: 'error',
        content: `Failed to add entry: ${data?.Message ?? data ?? 'unknown error'}`,
      }));
    }
  }, [isSuccess, data, isLoading, isError, dispatch]);

  return (
    <ContentLayout
      header={
        <Header variant='h1' description='Add an entry to your diary'>
          Add Entry
        </Header>
      }
    >
      <AuthedComponent>
        <Form
          actions={
            <Button
              variant='primary'
              loading={isLoading}
              onClick={(e) => {
                e.preventDefault();
                addEntry({ date: date.replace(/-/g, ''), entry });
              }}
            >
              Submit
            </Button>
          }
        >
          <Container>
            <SpaceBetween direction='vertical' size='l'>
              <FormField label='What did you do?'>
                <Textarea value={entry} onChange={({ detail }) => setEntry(detail.value)} placeholder='Diary entry' />
              </FormField>
              <FormField label='When did you do it?'>
                <DatePicker
                  value={date}
                  onChange={({ detail }) => setDate(detail.value)}
                />
              </FormField>
            </SpaceBetween>
          </Container>
        </Form>
      </AuthedComponent>
    </ContentLayout>
  );
};

export { AddEntry };

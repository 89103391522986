import { FetchBaseQueryError, TagDescription } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AddEntryRequest,
  AddEntryResponse,
  AddMonthSummary,
  AddWeekSummary,
  DateRequest,
  Entry,
  GenericResponse,
  MonthRequest,
  MonthSummary,
  WeekSummary,
} from '../types/diaryTypes.ts';
import { monday, strippedDateToReal } from '../utils/dateUtils.ts';
import { RootState } from './store.ts';

export const weekEntryTagType = 'WeekEntries' as const;
export const weekSummariesTagType = 'WeekSummaries' as const;
export const weekSummaryTagType = 'WeekSummary' as const;
export const monthSummaryTagType = 'MonthSummary' as const;
const tags = [weekEntryTagType, weekSummariesTagType, weekSummaryTagType, monthSummaryTagType] as const;
type TagTypes = typeof tags[number];

function tagIfNotError<T>(a: (req: T) => ReadonlyArray<TagDescription<TagTypes>>) {
  return (_result: unknown, error: FetchBaseQueryError | undefined, req: T) => !error ? a(req) : [];
}

function passThroughQuery(url: string) {
  return <T>(body: T) => ({
    url,
    body,
    method: 'POST',
  });
}

export interface FaunaWrapped<T> {
  data: T;
}

export const diaryApi = createApi({
  reducerPath: 'diaryApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      headers.set('Content-Type', 'application/json');
      return headers;
    },
    baseUrl: '/.netlify/functions',
  }),
  tagTypes: tags,
  endpoints: (build) => ({
    getWeek: build.query<FaunaWrapped<Entry[]>, DateRequest>({
      query: passThroughQuery('get_week'),
      providesTags: tagIfNotError((req) => [{ type: weekEntryTagType, id: req.date }]),
    }),
    getMonth: build.query<FaunaWrapped<WeekSummary[]>, MonthRequest>({
      query: passThroughQuery('get_month'),
      providesTags: tagIfNotError((req) => [{ type: weekSummariesTagType, id: req.month }]),
    }),
    getMonthSummary: build.query<MonthSummary | null, MonthRequest>({
      query: passThroughQuery('get_month_summary'),
      providesTags: tagIfNotError((req) => [{ type: monthSummaryTagType, id: req.month }]),
    }),
    getWeekSummary: build.query<WeekSummary | null, DateRequest>({
      query: passThroughQuery('get_week_summary'),
      providesTags: tagIfNotError((req) => [{ type: weekSummaryTagType, id: req.date }]),
    }),
    addEntry: build.mutation<AddEntryResponse, AddEntryRequest>({
      query: passThroughQuery('add_entry'),
      // transformErrorResponse: (),
      invalidatesTags: (_result, error, request) =>
        error ? [] : [{ type: weekEntryTagType, id: monday(strippedDateToReal(request.date)).replace(/-/g, '') }],
    }),
    addWeekSummary: build.mutation<GenericResponse, AddWeekSummary>({
      query: passThroughQuery('add_week'),
      invalidatesTags: (_result, error, request) => error ? [] : [{ type: weekSummaryTagType, id: request.date }],
    }),
    addMonthSummary: build.mutation<GenericResponse, AddMonthSummary>({
      query: passThroughQuery('add_month'),
      invalidatesTags: (_result, error, request) => error ? [] : [{ type: monthSummaryTagType, id: request.month }],
    }),
  }),
});

export const {
  useGetWeekQuery,
  useGetMonthQuery,
  useGetMonthSummaryQuery,
  useGetWeekSummaryQuery,
  useAddEntryMutation,
  useAddWeekSummaryMutation,
  useAddMonthSummaryMutation,
} = diaryApi;

import { Box } from '@cloudscape-design/components';
import React from 'react';

interface EmptyCollectionProps {
  name: string;
}

const EmptyCollection: React.FC<EmptyCollectionProps> = ({ name }) => (
  <Box
    margin={{ vertical: 'xs' }}
    textAlign='center'
    color='inherit'
  >
    <b>No {name}</b>
  </Box>
);

export { EmptyCollection };

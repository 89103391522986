import { Box } from '@cloudscape-design/components';
import React from 'react';

interface LineWrapperProps {
  text: string;
}

const LineWrapper: React.FC<LineWrapperProps> = ({ text }) => (
  <Box>
    {text.split(/\r?\n/).map(x => <p key={x}>{x}</p>)}
  </Box>
);

export { LineWrapper };

import { Alert, Button, SpaceBetween } from '@cloudscape-design/components';
import netlifyIdentity from 'netlify-identity-widget';
import React, { PropsWithChildren } from 'react';
import { selectAuthToken } from '../redux/authSlice';
import { useAppSelector } from '../redux/hooks';

const AuthedComponent: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useAppSelector(selectAuthToken);
  if (user) {
    return children;
  } else {
    return <Login />;
  }
};

const Login: React.FC = () => {
  return (
    <SpaceBetween direction='vertical' size='xl'>
      <Alert type='error' header='You must login to view this page'>
        Please login to view this page
      </Alert>
      <Button
        variant='primary'
        onClick={(e) => {
          console.log('Clicked login');
          e.preventDefault();
          netlifyIdentity.open('login');
        }}
      >
        Login
      </Button>
    </SpaceBetween>
  );
};

export { AuthedComponent };

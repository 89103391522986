const padNum = (n: number) => String(n).padStart(2, '0');

export function toDatePickerString(date: Date): string {
  // YYYY-MM-DD
  return `${date.getFullYear()}-${padNum(date.getMonth() + 1)}-${padNum(date.getDate())}`;
}

export function monday(startDate?: Date) {
  const date = startDate ?? new Date();
  while (date.getDay() !== 1) {
    date.setTime(date.getTime() - (24 * 60 * 60 * 1000));
  }
  return toDatePickerString(date);
}

export function strippedDateToReal(stripped: string) {
  // When the time zone offset is absent, date-only forms are interpreted as a UTC time and date-time forms are interpreted as local time.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
  const date = `${stripped.substring(0, 4)}-${stripped.substring(4, 6)}-${stripped.substring(6)}T12:00`;
  return new Date(date);
}

export function nicefyDate(stripped: string) {
  return strippedDateToReal(stripped).toLocaleDateString();
}

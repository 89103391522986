import {
  Button,
  Cards,
  Container,
  ContentLayout,
  DatePicker,
  Form,
  FormField,
  Header,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useRtkErrorNotification } from '../hooks/rtkErrorNotification';
import { useAddMonthSummaryMutation, useGetMonthQuery, useGetMonthSummaryQuery } from '../redux/apiSlice';
import { useAppDispatch } from '../redux/hooks';
import { addNotification } from '../redux/notificationSlice';
import { nicefyDate } from '../utils/dateUtils';
import { AuthedComponent } from './AuthedComponent';
import { LineWrapper } from './LineWrapper';

function firstDay() {
  const date = new Date();
  date.setDate(1);
  return date.toISOString().split('T')[0];
}

const MonthSummary: React.FC = () => {
  const [summary, setSummary] = useState('');
  const [month, setMonth] = useState(firstDay());
  const payload = useMemo(() => ({ month: month.replace(/-/g, '').substring(0, 6) }), [month]);
  const dispatch = useAppDispatch();

  const { data, error, isLoading, isError } = useGetMonthQuery(payload);
  const summaries = data?.data;
  const {
    data: existingSummary,
    currentData: currentSummary,
    error: summaryError,
    isLoading: summaryLoading,
    isError: isSummaryError,
  } = useGetMonthSummaryQuery(payload);
  const [addMonthSummary, { isLoading: adding, isSuccess, isError: isAddError, data: result }] =
    useAddMonthSummaryMutation();

  useRtkErrorNotification(error, isError, 'Failed to fetch week summaries');
  useRtkErrorNotification(summaryError, isSummaryError, 'Failed to fetch month summary');

  useEffect(() => {
    setSummary(existingSummary?.Summary ?? '');
  }, [existingSummary]);

  const fetching = isLoading || summaryLoading;

  useEffect(() => {
    if (adding) return;
    if (isSuccess) {
      dispatch(addNotification({
        type: 'success',
        content: result?.Message ?? 'Successfully added month summary',
      }));
    } else if (isAddError) {
      dispatch(addNotification({
        type: 'error',
        content: result?.Message ?? 'Failed to add month summary',
      }));
    }
  }, [adding, isSuccess, isAddError, result, dispatch]);

  return (
    <ContentLayout
      header={
        <Header variant='h1' description='Summarise a month'>
          Summarise Month
        </Header>
      }
    >
      <AuthedComponent>
        <SpaceBetween direction='vertical' size='xl'>
          <Form
            actions={
              <Button
                variant='primary'
                disabled={fetching}
                loading={adding}
                onClick={(e) => {
                  e.preventDefault();
                  addMonthSummary({ ...payload, summary, update: !!currentSummary });
                }}
              >
                Submit
              </Button>
            }
          >
            <Container>
              <SpaceBetween direction='vertical' size='l'>
                <FormField label='Month summary'>
                  <Textarea value={summary} onChange={({ detail }) => setSummary(detail.value)} />
                </FormField>
                <FormField label='Month'>
                  <DatePicker
                    isDateEnabled={date => date.getDate() == 1}
                    value={month}
                    onChange={({ detail }) => setMonth(detail.value)}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
          <Cards
            items={summaries ?? []}
            loading={isLoading}
            cardDefinition={{
              header: e => nicefyDate(e.StartDate),
              sections: [
                {
                  id: 'entry',
                  content: e => <LineWrapper text={e.Summary} />,
                },
              ],
            }}
            header={<Header>Entries for this week</Header>}
          />
        </SpaceBetween>
      </AuthedComponent>
    </ContentLayout>
  );
};

export { MonthSummary };

import { Flashbar, FlashbarProps } from '@cloudscape-design/components';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { dismissNotification, selectNotifications } from '../redux/notificationSlice';

const MyFlashbar: React.FC = () => {
  const notifications = useAppSelector(selectNotifications);
  const dispatch = useAppDispatch();
  const items: FlashbarProps.MessageDefinition[] = notifications.map(({ type, content, id }) => ({
    type,
    dismissible: true,
    dismissLabel: 'Dismiss message',
    onDismiss: () => dispatch(dismissNotification(id)),
    content,
    id: `${id}`,
  }));

  return <Flashbar items={items} />;
};

export default MyFlashbar;
